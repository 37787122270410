<template>
  <div class="home">
    <Navbar />
    <Cards />
    <Section />
    <Testimonial />
    <Testimonialwork/>
  </div>
</template>

<script>
import Navbar from '../components/Barnerd.vue'
import Cards from '../components/Cards.vue'
import Section from '../components/SectionAbout.vue'
import Testimonial from '../components/Testimonial.vue'
import Testimonialwork from '../components/Testimonialwork.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    Cards,
    Section,
    Testimonial,
    Testimonialwork
  }
}
</script>
