<template>
  <div class="fondo py-8  md:py-20 text-gray-200">
    <div class="flex flex-col mt-20 md:flex-row sm:px-20  lg:pb-40 lg:px-40 lg:justify-end">
      <div class="md:w-1/2 md:pl-8 md:pr-4">
        <p class="pb-8 text-xl md:text-xl font-bold">{{ titulo }}</p>
        <p class="pb-10 text-lg  md:text-xl">{{ parrafo }}</p>
        <div class="pb-10 text-center md:text-left mt-5 md:mt-0">
          <router-link class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
             to="/about">More</router-link>
       </div>

      </div>
      <div class="md:w-1/2 mt-5 md:mt-0">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titulo: 'ABOUT US',
      parrafo: 'We are a local family owned and operated company that strives for excellence always. We are dedicated to serving every customer as if they were our only customer. Always striving to be the best that we can and delivering the best possible result in bringing the customer’s vision to life.',
    };
  },
};
</script>

<style scoped>


.fondo {
 
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(17, 105, 140, 0.70)), url("../assets/nv/fabout.webp");
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 100%;
   
}
.bg {
  opacity: 80%;
  background-color: rgba(17, 105, 140, 0.70);
}

</style>

