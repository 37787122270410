<template>
  <div class="sticky top-0 z-50">
    <div class="bg-gray-900 h-16">
      <div
        class="
          text-white
          h-full
          text-center
          flex
          md:justify-around
          justify-between
          items-center
          mx-4 md:mx-0
        "
      >
        <router-link id="logo" class="text-2xl cursor-pointer block w-40" to="/home">
          <img src="../assets/img/LogoN.png" alt="" class="">
        </router-link>
        <div class="hidden md:flex md:justify-evenly w-auto">
          <router-link class="link-one" to="/home">Home</router-link>
          <router-link class="link-one" to="/about">About us</router-link>
             <router-link class="link-one" to="/post">Services</router-link>
          <router-link class="link-one" to="/galery">Galery</router-link>
          <router-link class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
             to="">+1 (919) 805-7952</router-link>
         
        </div>
        <i class="md:hidden cursor-pointer bg-gray-400 w-10 h-10 rounded-full center" @click="view = !view">
          <fa icon="bars" />
        </i>
      </div>
    </div>
    <div v-if="!view" id="links" class="md:hidden text-white absolute top-16 left-0 bg-gray-600 w-full center flex-col">
      <router-link @click="view = true" class="link-two" to="/home">Home</router-link>
      <router-link @click="view = true" class="link-two" to="/about">About us</router-link>
      <router-link class="link-one" to="/post">Services</router-link>
      <router-link @click="view = true" class="link-two" to="/galery">Galery</router-link> 
     <!--  <router-link @click="view = true" class="w-full text-center py-3 text-lg md:px-3 md:py-2 bg-red-600 rounded-md" to="/donate">
          <fa icon="heart" class="mr-2 ic" /></router-link> -->
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      view: true

    }
  }
}
</script>
<style scoped>
.show {
  background-color: black;
}
.link-one {
  @apply block py-3 text-lg md:px-3 md:py-2 focus:bg-yellow-600 md:rounded-xl md:mx-2 hover:bg-yellow-500;
}
.link-two {
  @apply block py-3 text-lg hover:bg-yellow-500;
}

.ic {
  animation: ping 1s infinite;
  transition: all ease;
}

@keyframes ping {
  to {
    transform: scale(1.2);
  }
  from {
    transform: scale(1);
  }
}
</style>
