<template>
  <div class="fondo py-8 md:py-40  md:px-6 lg:px-24 bg-gray-900 text-gray-200">
    <div class="flex flex-col justify-center md:flex-row sm:px-20 lg:pb-20 lg:px-40 lg:justify-end">
      <div class="w-full md:w-1/2 mb-8 md:mb-0 md:pl-8 md:pr-4">
        <div class=" text-3xl display: inline-block md:text-5xl font-bold text-center md:text-left"><p class="colors">{{ 'Some testimonials that ' }}</p></div>
        <div class="text-3xl md:text-5xl display: inline-block text-center md:text-left">{{ 'speak about our service' }}</div>
      </div>
      <div class="w-full md:w-1/2 pl-4 md:pl-8">
        <p class="text-lg md:text-2xl font-medium text-center md:text-left">{{ "We are proud of our work" }}</p>
        <p class=" text-lg md:text-2xl font-medium text-center md:text-left">{{ "Performing our quality experience" }}</p>
      </div>
    </div>
    <div class="flex flex-col justify-center md:flex-row md:justify-center md:flex-wrap">
      <div v-for="card in cards" :key="card" class="my-4 md:my-2 w-full md:w-80 md:w-1/3">
        <div class="bg-gray-800 border-2 border-orange-600 md:h-80 py-5 mx-2 md:mx-4 lg:mx-6 px-2 rounded-md shadow-xl">
          <div class="text-center md:text-left mx-4">
            <fa :icon="card.icon" class="text-yellow-700 inline-block my-4 text-5xl lg:text-6xl mb-6 fi fi-rr-user" />
            <h1 class="text-xl lg:text-2xl 	display: inline-block font-bold mb-4 text-white">{{ card.title }}</h1>
            <p class="text-sm lg:text-base 	display: inline-block font-normal h-20 lg:h-32 leading-5 text-white">{{ card.content }}</p>
          </div>
        </div>
      </div>
</div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      cards: [
        {
          title: 'Ellen Williams',
          content: 'Jose and team are great to work with and can literally do anything! They painted, installed carpet, refinished hardwoods, repaired drywall, and more. Very affordable and reliable. I will definitely be referring them and using them again in the future. ',
         
        },
        {
          title: 'Bob Tassone ',
          content: 'I hired New Valley Painting to paint my Garage at the suggestion of my neighbor. The work was exceptional and the painters were very professional, hard working and focused. The completed the job ahead of schedule, cleaned up meticulously and Jose followed up to see if the work Was satisfactory at the end of each day. Great painting and hard working people.',
         
        },
        {
          title: 'Ron Ellis',
          content: 'Jose was recommend to me by a fence company to stain my newly installed wood privacy fence. From my first contact for a quote and information, Jose was extremely responsive.l was very impressed with Jose and the quality of his work. i hope to utilize New Valley Painting for other upcoming projects.',
         
        },
      ]
    };
  }
};
</script>

<style scoped>
.fondo {
 
  background-image: linear-gradient(rgba(0, 0, 0, 0.856), rgba(17, 105, 140, 0.418)), url("../assets/nv/ftestimony.webp");
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 100%;
   
}
.bg {
  opacity: 80%;
  background-color: rgba(17, 105, 140, 0.70);
}

.bg2 {
  /* background-color: #024873; */
 color: rgb(146 64 14);;
}

.colors {
      color:rgb(234 88 12);
    }

</style>
