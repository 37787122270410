<template>
  <div class="">
    <div class="bg-gray-900 text-white w-full md:h-60 px-32 flex md:flex-row flex-col-reverse items-center justify-around">
      <img src="../assets/img/LogoF.png" class="md:w-64 md:h-40 h-auto w-64 md:mb-0 mb-5">
      <div>
        <ul>
          <li v-for="info in infos" :key="info" class="mb-3 md:w-auto w-48">
            <a :href="info.enlace" class="flex items-center md:text-lg text-xl font-extralight">
              <img :src="info.icon" class="mr-2">
                {{ info.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="text-center flex flex-col h-full">
        <h3 class="uppercase font-medium text-2xl md:mt-0 mt-5"></h3>
        <p class="md:w-96 w-80 font-light md:m-5 px-7 my-5"></p>
      </div>
    </div>
    <div class="bg-gray-900 text-white w-full h-10 font-light center text-center">
      Copyright &copy;2023NewValley 
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      infos: [
        {
          icon: require('@/assets/icons/001-facebook.png'),
          title: 'Facebook',
          enlace: 'https://www.facebook.com/newvalleypaintingrdu/'
        },
        {
          icon: require('@/assets/icons/003-instagram.png'),
          title: 'nvpnting@gmail.com',
          enlace: ''
        },
        {
          icon: require('@/assets/icons/005-whatsapp.png'),
          title: '+1 (919) 805-7952',
          enlace: 'https://api.whatsapp.com/send?phone=19198057952&app=facebook&entry_point=page_cta'
        }
      ]
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
.title {
  font-family: 'Raleway', sans-serif;
}
</style>
