<template>
  <div class="flex py-8 md:py-60 text-gray-200 bg-gray-900">
    <div class="flex-1 flex flex-col md:px-5">
      <div class="pl-6 md:pl-60">
        <p class="text-2xl md:text-5xl font- md:text-left">Ready to transform  </p>
        <p class="text-2xl md:text-5xl font-bold md:text-left">Your home</p>
      </div>
      <div class="pl-4 md:pl-96 pr-6 md:pr-72 mt-4 md:mt-10">
        <p class="text-sm md:text-2xl text-center md:text-left">{{ parrafo }}</p>
      </div>
      <section class="overflow-hidden">
        <div class="container px-4 py-2 md:pt-14 md:px-32">
          <div class="flex flex-col md:flex-row md:-mx-2">
            <div class="md:w-1/2"></div>
            <div class="flex flex-col md:w-4/5 md:flex-row">
              <div class="md:w-1/2 sm:w-full p-2 md:p-4 mt-4">
                <img alt="gallery" class="image object-cover w-full h-60 sm:h-auto" src="@/assets/nv/image1.webp">
              </div>
              <div class="md:w-1/2 sm:w-full p-2 md:p-4 mt-4">
                <img alt="gallery" class="image object-cover w-full h-60 sm:h-auto" src="@/assets/nv/image0.webp">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  components: { },
  data () {
    return {
      parrafo: 'Contact New Valley Painting today to schedule your consultation and start the journey toward a beautifully painted home.',

    }
  }
}
</script>
<style scoped>

@media (min-width: 640px) {
  .testimonial {
    width: 100%;
    height: 70vh;
  }
}
</style>
