<template>
  <div class="w-full h-screen overflow-hidden">
    <div class="h-full w-full bg-gray-900 px-4 md:px-8">
      <div id="" class="text-gray-300 h-full">
        <div class="fondo w-full h-full flex flex-col justify-center items-center md:items-start relative bg-cover">
          <div class="absolute top-1/2 transform -translate-y-1/2 text-center pt-60 md:text-left px-4 md:px-10">
            <div class="text-4xl md:text-7xl  md:pl-80">
              {{ verse }}
            </div>
            <div class="text-4xl  md:text-7xl font-bold mb-4 md:pl-80">
              <p class="colors">{{ verse2 }}</p>
            </div>
            <div class="text-lg md:text-2xl font-normal mb-4 md:pl-80">
              {{ text }}
            </div>
            <div class="text-lg md:text-2xl font-normal mb-4 md:pl-80">
              {{ text2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      verse: 'New Valley ',
      verse2: 'Painting',
      text: '"The brush of quality , the stroke of exellence"',
      text2: ''
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");

#title {
  font-family: "Acme", sans-serif;
}
.fondo {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/nv/home1.webp");
  width: 100%;
  height: 100%;
}
.colors {
      color:rgb(234 88 12);
    }
@screen sm {
  header {
    height: 660px;
  }
}
@screen lg {
  header {
    height: 800px;
  }
}

</style>
