<template>
  <div class="py- md:py-40  md:px-6 lg:px-24 bg-gray-900 text-gray-200">
    <div class="flex flex-col justify-center md:flex-row sm:px-20 lg:pb-20 lg:px-40 lg:justify-end">
      <div class="w-full md:w-1/2 mb-8 md:mb-0 md:pl-8 md:pr-4">
        <div class="text-amber-700 text-3xl md:text-4xl font-bold text-center md:text-left">{{ 'We are a pro painters,' }}</div>
        <div class="text-3xl md:text-5xl text-center md:text-left">{{ 'turning houses into homes, one stroke at a time' }}</div>
      </div>
      <div class="w-full md:w-1/2 pl-4 md:pl-8">
     
      </div>
    </div>
   
  </div>
</template>


<script>
export default {
  data () {
    return {

      cards: [
        {
          title: 'Family Owned & Operated',
          content: 'We are a local business that is proud to serve our community, and our family-owned & operated business allows us to give special attention to every project.',
          icon: 'building'
        },
        {
          title: '2 Year Warranty',
          content: 'We pride ourselves on each and every project we complete and strive for perfection. That’s why we offer a 2-year workmanship warranty to hold ourselves up to that standard.',
          icon: 'building'
        },
        {
          title: 'Fully Insured',
          content: 'Rest easy, our company’s insurance protects your property from any job site accidents. Don’t take the risk of hiring an uninsured painting contractor.',
          icon: 'building'
        },
        
      ]
    }
  }
}
</script>

