<template>
  <div>
    <navbar />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Navbar from './views/Navbar.vue'
import Footer from './views/Footer.vue'
export default {
  components: {
    Navbar,
    Footer
  }
}
</script>
<style>
.center {
  @apply flex justify-center items-center;
}
.center-c {
  @apply flex flex-col justify-center items-center;
}
.title {
  @apply text-center py-5 font-medium text-4xl;
}
/*
COLORES:

#024873 - azul
#11698C - menta
#176273 - green
#F2F2F2 - white
#0D0D0D - black
*/

/* COLORES */
/*
#B7A7F2
#C4BDF2
#8DEBF2
#D7F2AE
#F2F2F2
*/
.bg1 {
  background-color: #B7A7F2;
}
.bg2 {
  background-color: #C4BDF2;
}
.bg3 {
  background-color: #8DEBF2;
}
.bg4 {
  background-color: #D7F2AE;
}
.bg5 {
  background-color: #F2F2F2;
}
</style>
